.Chat {
  position: fixed;
  width: 100vw;
  top: 0;
  height: 100vh;
  z-index: 1;
  text-align: center;
  overflow-y: scroll;
  display: flex;
  flex-direction: column-reverse;
  -webkit-mask-image: -webkit-gradient(linear, left center, 
    left 15%, from(rgba(0,0,0,1)), to(rgba(0,0,0,0)));
}

@media (max-width: 600px) {
  .Chat {
    -webkit-mask-image: -webkit-gradient(linear, left 60%, 
    left 50%, from(rgba(0,0,0,1)), to(rgba(0,0,0,0)));
  }
}

.centered{
  background-color: #282c34;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  flex-direction: column;
  gap: 10px;
  color: white
}

.Chat-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .Chat-logo {
    animation: Chat-logo-spin infinite 20s linear;
  }
}

.Chat-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

.input-box-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 0px;
  width: 100vw;
}

.input-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: calc(100vw - 10px);
  max-width: 450px;
  padding: 5px;
}

.input-text {
  width: 100%;
}

.input-button {
  width: 100px;
}

input {
  flex-grow: 1;
  width: 100%;
  border: 2px solid #f1f1f1;
  padding: 10px;
  background: #f1f1f1;
  outline: none;
  border-radius: 10px;
  border: 1px solid black;
}

button {
  padding: 10px;
  width: 30%;
  border: none;
  background: #299650;
  border-radius: 10px;
  border: 1px solid black;
  margin-left: 25px;
  color: #fff;
  cursor: pointer;
}

button:disabled {
  opacity: .5;
}

button:hover {
  opacity: .5;
}

.message {
  border: 1px solid black;
  opacity: 0;
  background: #f1f1f1;
  margin: 5px 5px;
  margin-left: 0;
  border-radius: 10px;
  padding: 5px;
  padding-right: 10px;
  text-align: left;
  width: max-content;
  display: flex;
  animation-name: animate-fade;
  animation-delay: 0.1s;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
}

@keyframes animate-fade {
  0% {
    opacity: 0;
    transform: scale(0.5, 0.5);
  }
        
  100% {
    opacity: 1;
    transform: scale(1, 1);
  }
}

.messages>ul {
  padding-left: 5px;
  padding-bottom: 30px;
}

.message>img {
  background: #fff;
  width: 30px;
  height: 30px;
  object-fit: contain;
  border-radius: 9999px;
  margin-right: 10px;
}

.message>div span {
  display: block;
  font-size: 10px;
}

.message>div {
  display: flex;
  flex-direction: column;
  max-width: 30vw;
}

@media (max-width: 600px) {
  .message>div {
    max-width: 70vw;
  }
}

.name-submit{
  color: white;
  width: 200px;
}